import {useEffect} from "react";
import {Outlet} from "react-router-dom";
import {Header} from "../modules/Header/Header";
import {Footer} from "../modules/Footer/Footer";


export function HowToWork () {

    useEffect(() => {
        document.title = "Как пользоваться порталом";
    }, []);

    return (
        <>
            <Header />
            <main id="main" role="main">
                <Outlet />
            </main>
            <Footer />
        </>
    )
}