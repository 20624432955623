import React from 'react'
import styles from './ChangePasswordSuccess.module.scss'


export const ChangePasswordSuccess = () => {

  return (
    <section className={styles.success}>
      <p className={styles.text}>Пароль успешно 
      изменен</p>

      <img 
        src="/images/success.svg"
        alt="check"
      />
    </section>
  )
}