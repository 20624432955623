import styles from './how-to-work.module.scss'
import { useActor } from "@xstate/react";
import { AuthState } from "@/js/xstate/auth.machine";

export function HowToWorkMedia() {
  const [authState] = useActor(AuthState);


  const videoPath = authState.context.type === 'seller' ? 
    'https://zictop.ru/s/video/ZIC%20%D0%9F%D0%A0%D0%9E%D0%A4%D0%98_%D0%9F%D1%80%D0%BE%D0%B4%D0%B0%D0%B2%D0%B5%D1%86.mp4' : 
    authState.context.type === 'distributor' ? 
    'https://zictop.ru/s/video/ZIC%20%D0%9F%D0%A0%D0%9E%D0%A4%D0%98_%D0%94%D0%B8%D1%81%D1%82%D1%80%D0%B8%D0%B1%D1%8C%D1%8E%D1%82%D0%BE%D1%80.mp4' : 
    'https://zictop.ru/s/video/ZIC%20%D0%9F%D0%A0%D0%9E%D0%A4%D0%98_%D0%9F%D0%B0%D1%80%D1%82%D0%BD%D0%B5%D1%80%D1%8B.mp4'

  return (
    <div className={styles.howToWork}>
      <h1 className={styles.title}>Обучающее видео как пользоваться порталом</h1>
      <video 
        className={styles.video}
        src={videoPath}
        preload
        controls
        controlsList="nodownload"
      />
    </div>
  )
}