import {assign, createMachine} from "xstate";
import apiMethods from "../api/apiMethods";

export const DocsMachine = createMachine({
    id: "docs",
    context: {
      docs: [],
      errorMsg: [],
    },
    initial: "loading",
    states: {
      "loading": {
        invoke: {
          id: "getData",
          src: "getData",
        },
        on: {
          "done": {
            target: "success",
            actions: ["saveData"]
          },
          "error": {
            target: "failure",
            actions: ["setError"]
          }
        },
        entry: "scrollTop",
      },
      "success": {
          on: {
              "saveData": {
                  actions: ["saveData"],
                  target: "loading"
              },
              // "nextPage": {
              //     actions: ["changePage"],
              //     target: "loading",
              //     cond: "isntLastPage"
              // },
          }
      },
      "failure": {
        on: {
          "retry": {
            target: "loading"
          }
        }
      }
    }
}, {
    actions: {
        "saveData": assign({
          "docs": (ctx, message) => message.data.data,
        }),
        "setError": assign((ctx, message) => message.data ?? ''),
    },
    services: {
      "getData": (ctx, message) => {
        return async function (send) {
          const response = await apiMethods.docs.getDocs();
          
          if (response.status === 200) {
            send({
              type: "done",
              data: response.data,
            })
          } else {
            send({
              type: "error",
              data: response.data
            })
          }
        }
      }
    }
});