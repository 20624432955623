import React, { useEffect, useCallback, useState } from "react";
import { useActor, useMachine } from "@xstate/react";
import clsx from "clsx";
import { Popover, PopoverDisclosure, usePopoverState , useDialogState } from "reakit";
import InputMask from "react-input-mask";
import { AuthState } from "../../xstate/auth.machine";
import styles from "./MyInfo.module.scss";
import { Icon } from "../SVGsprite/svgsprite";
import { userCardMachine } from "../../xstate/userCard.machine";
import { Loader } from "../Loader/Loader";
import apiMethods from "../../api/apiMethods";
import { BannersModal } from "../BannerModal/BannerModal";
import { useNavigate } from "react-router-dom";

export function MyInfo() {
  // const [stateAuth, sendAuth] = useActor(AuthState);
  const [stateUserCard, sendUserCard] = useMachine(userCardMachine);
  const [loading, setLoading] = useState(false);
  const [isDisplaedBanner, setIsDisplayedBanner] = useState(Boolean(localStorage.getItem('displayedBanner')))
  const [banners, setBanners] = useState([]);
  const dialog = useDialogState({ animated: true, visible: true });
  const navigate = useNavigate()

  const fetchBanners = useCallback(async () => {
      setLoading(true);
      const response = await apiMethods.banners.getBanners();
      setBanners(response.data.data);
      setLoading(false);
  }, []);

  const handleClose = () => {
    setIsDisplayedBanner(true)

    localStorage.setItem('displayedBanner', true)
  }

  useEffect(() => {
    if (stateUserCard?.history?.value === "sendDataPassword") {
      navigate('/account/success')
    }
  }, [stateUserCard])

  useEffect(() => {
      fetchBanners();
  }, [fetchBanners]);


  useEffect(() => {
    document.title = "Редактировать профиль | Программа лояльности SK ZIC";

  }, []);

  return (
    <section className={styles.myInfo}>
      <h1>Редактировать профиль</h1>

      {stateUserCard.context.message.length > 0 ? (
        <div className="errorMessage">{stateUserCard.context.message}</div>
      ) : (
        <></>
      )}

      {stateUserCard.matches("sendDataPassword") ||
      stateUserCard.matches("sendDataUser") ||
      stateUserCard.matches("processing") ? (
        <div className="fullPageLoader">
          <Loader />
        </div>
      ) : (
        <UserCard
          data={stateUserCard.context.userData}
          errors={stateUserCard.context.errors}
          sendUserCard={sendUserCard}
        />
      )}

      {!isDisplaedBanner ? 
        banners.map((item, idx) => <BannersModal onClose={handleClose} key={`banner-${idx}`} loading={loading} banner={item} dialog={dialog} />) : <></>}
    </section>
  );
}

function UserCard(props) {
  const [stateAuth] = useActor(AuthState);
  const [phoneVal, setPhoneVal] = useState(props.data.phone);
  const [image, setImage] = useState(null);

  /* Popovers */
  const popoverName = usePopoverState({ placement: "left" });
  const popoverEmail = usePopoverState({ placement: "left-end" });
  const popoverShop = usePopoverState({ placement: "left-end" });

  const imageAdd = (event) => {
    const element = event.currentTarget;
    const fileList = element.files;

    setImage(fileList[0]);
  };

  function updateData(event) {
    event.preventDefault();

    const formData = new FormData();
    if (image) formData.append("avatar", image);
    formData.append("phone", phoneVal);
    try {
      props.sendUserCard({
        type: "updateUser",
        data: formData,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async function updatePassword(event) {
    event.preventDefault();

    try {
      await props.sendUserCard({
        type: "updatePassword",
        data: event.target,
      });

    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    setPhoneVal(parseInt(props.data.phone));
  }, [props.data.phone]);

  return (
    <>
      <form onSubmit={(event) => updateData(event)}>
        <div className={clsx(styles.userpicRow, "formRow")}>
          <div className={styles.userpic}>
            <img
              src={
                image
                  ? URL.createObjectURL(image)
                  : props.data.avatar
                  ? props.data.avatar
                  : "/images/user-ph.png"
              }
              alt="Фото пользователя"
            />
          </div>

          <div className={styles.buttons}>
            <div className="customFileInput">
              <input
                id="avatar"
                name="avatar"
                type="file"
                accept="image/*"
                multiple={false}
                onChange={(event) => imageAdd(event)}
              />
              <label htmlFor="avatar">
                {props.data.avatar ? "Сменить фото" : "Добавить фото"}
              </label>
            </div>
          </div>

          {!!Object.keys(props.errors).length &&
          props.errors.hasOwnProperty("avatar") ? (
            <div className="errorMessage" key="errEntityName">
              {props.errors.avatar[0]}
            </div>
          ) : (
            <></>
          )}
        </div>

        {(stateAuth.context.type === "seller" ||
          stateAuth.context.type === "owner") && (
          <>
            <div className="formRow">
              <label htmlFor="userName">Имя</label>
              <div className="inputWrapper">
                <input
                  type="text"
                  id="userName"
                  defaultValue={props.data.name}
                  disabled
                />
                <PopoverDisclosure {...popoverName}>
                  <span className="visuallyHidden">Открыть подсказку</span>
                  <Icon
                    name="icon-question"
                    sizeW="24"
                    sizeH="24"
                    sizeWV="32"
                    sizeHV="32"
                  />
                </PopoverDisclosure>
                <Popover
                  className="popover"
                  {...popoverName}
                  aria-label="Подсказка"
                >
                  Чтобы внести изменения свяжитесь с администратором по почте{" "}
                  <a href="mailto:admin@zicoil.ru">admin@zictop.ru</a>
                </Popover>
              </div>
            </div>
          <div className="formRow">
            <label htmlFor="userName">Фамилия</label>
            <div className="inputWrapper">
              <input
                type="text"
                id="userName"
                defaultValue={props.data.surname}
                disabled
              />
              <PopoverDisclosure {...popoverName}>
                <span className="visuallyHidden">Открыть подсказку</span>
                <Icon
                  name="icon-question"
                  sizeW="24"
                  sizeH="24"
                  sizeWV="32"
                  sizeHV="32"
                />
              </PopoverDisclosure>
              <Popover
                className="popover"
                {...popoverName}
                aria-label="Подсказка"
              >
                Чтобы внести изменения свяжитесь с администратором по почте{" "}
                <a href="mailto:admin@zicoil.ru">admin@zictop.ru</a>
              </Popover>
            </div>
          </div>
          </> 
          
        )}
        {stateAuth.context.type === "distributor" && (
          <div className="formRow">
            <label htmlFor="company">Название компании или ИП</label>
            <div className="inputWrapper">
              <input
                type="text"
                id="company"
                defaultValue={props.data.name}
                disabled
              />
              <PopoverDisclosure {...popoverShop}>
                <span className="visuallyHidden">Открыть подсказку</span>
                <Icon
                  name="icon-question"
                  sizeW="24"
                  sizeH="24"
                  sizeWV="32"
                  sizeHV="32"
                />
              </PopoverDisclosure>
              <Popover
                className="popover"
                {...popoverShop}
                aria-label="Подсказка"
              >
                Чтобы внести изменения свяжитесь с администратором по почте{" "}
                <a href="mailto:admin@zicoil.ru">admin@zictop.ru</a>
              </Popover>
            </div>
          </div>
        )}
        <div className="formRow">
          <label htmlFor="email">Email</label>
          <div className="inputWrapper">
            <input
              type="text"
              id="email"
              defaultValue={props.data.email}
              disabled
            />
            <PopoverDisclosure {...popoverEmail}>
              <span className="visuallyHidden">Открыть подсказку</span>
              <Icon
                name="icon-question"
                sizeW="24"
                sizeH="24"
                sizeWV="32"
                sizeHV="32"
              />
            </PopoverDisclosure>
            <Popover
              className="popover"
              {...popoverEmail}
              aria-label="Подсказка"
            >
              Чтобы внести изменения свяжитесь с администратором по почте{" "}
              <a href="mailto:admin@zicoil.ru">admin@zictop.ru</a>
            </Popover>
          </div>
        </div>
        {stateAuth.context.type === "seller" && (
          <div className="formRow">
            <label htmlFor="shopName">Торговая точка</label>
            <div className="inputWrapper">
              <input
                type="text"
                id="shopName"
                defaultValue={props.data.distributor}
                disabled
              />
              <PopoverDisclosure {...popoverShop}>
                <span className="visuallyHidden">Открыть подсказку</span>
                <Icon
                  name="icon-question"
                  sizeW="24"
                  sizeH="24"
                  sizeWV="32"
                  sizeHV="32"
                />
              </PopoverDisclosure>
              <Popover
                className="popover"
                {...popoverShop}
                aria-label="Подсказка"
              >
                Чтобы внести изменения свяжитесь с администратором по почте{" "}
                <a href="mailto:admin@zicoil.ru">admin@zictop.ru</a>
              </Popover>
            </div>
          </div>
        )}
        <div className="formRow">
          <label htmlFor="phone">Номер телефона</label>
          <div className="inputWrapper">
            <InputMask
              id="phone"
              name="phone"
              className="phone"
              mask="+7 (999) 999 99 99"
              maskPlaceholder={null}
              value={phoneVal}
              onChange={(event) => setPhoneVal(event.target.value)}
            />
          </div>
          <span className="note">Формат: 10 цифр</span>

          {!!Object.keys(props.errors).length &&
          props.errors.hasOwnProperty("phone") ? (
            <div className="errorMessage" key="errEntityName">
              {props.errors.phone[0]}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="formRow">
          <button type="submit" className="btn btnPrimary" disabled={!phoneVal}>
            Обновить данные
          </button>
        </div>
      </form>
      <form onSubmit={(event) => updatePassword(event)}>
        <h2>Сменить пароль</h2>
        <div className="formRow">
          <label htmlFor="current_password">Текущий пароль</label>
          <input
            id="current_password"
            name="current_password"
            type="password"
            autoComplete="current-password"
          />
          {!!Object.keys(props.errors).length &&
          props.errors.hasOwnProperty("current_password") ? (
            <div className="errorMessage" key="errEntityName">
              {props.errors.current_password[0]}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="formRow">
          <label htmlFor="new_password">Новый пароль</label>
          <input
            id="new_password"
            name="new_password"
            type="password"
            autoComplete="new-password"
          />
          {!!Object.keys(props.errors).length &&
          props.errors.hasOwnProperty("new_password") ? (
            <div className="errorMessage" key="errEntityName">
              {props.errors.new_password[0]}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="formRow">
          <button type="submit" className="btn btnPrimary">
            Обновить данные
          </button>
        </div>
      </form>
    </>
  );
}
