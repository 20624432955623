import clsx from "clsx";
import {useCallback, useEffect, useState} from "react";
import styles from "./Footer.module.scss"
import {Loader} from "../Loader/Loader";
import apiMethods from "../../api/apiMethods";

export function Footer({account = false}) {
    const [year, setYear] = useState();
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        setYear(new Date().getFullYear());
    },[]);

    const fetchDocuments = useCallback(async () => {
        setLoading(true);
        try {
            const {data} = await apiMethods.docs.getDocs();
            // const hasCode = Object.keys(data).includes("code");
            // if (hasCode) {
            //     return null
            // }
            //     setFiles(data)

            setLoading(false);
        } catch (error) {
            console.log(error.response.data.message);
        }
    }, []);

    useEffect(()=>{
        fetchDocuments();
    }, [fetchDocuments]);

    return(<footer className={clsx({[styles.account]: account}, styles.footer)}>
        <div className={clsx("wrapper", styles.wrapper)}>
            <div className={styles.copyrights}>
                ©{year} Смазочные материалы торговой марки ZIC, ООО «СК Энмув Рус» 
            </div>
            <div className={styles.links}>
                <a href="https://zicoil.ru" target="__blank">Перейти на основной сайт</a>
                {
                    loading
                        ? <Loader/>
                        : <>
                            {
                                files.length ? files.map(item => <a href={item.link} key={item.id}>{item.name}</a>) : <></>
                            }
                        </>
                }
            </div>
        </div>
    </footer>)
}
